import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import EventsService from "../services/EventsService";

import { TimelineSelector } from "../components/TimelineSelector";
import { TimepointCalendar } from "../components/TimepointCalendar";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

const actionsColorMap = ["#5eaa46", "#358dd2", "#d23561", "#d2c135"];

const exponentialBackoff = (max, delay, toTry, success, fail) => {
  toTry()
    .then((result) => success(result))
    .catch((_) => {
      if (max === 0) {
        return fail();
      }
      setTimeout(function () {
        exponentialBackoff(--max, delay * 2, toTry, success, fail);
      }, delay * 1000);
    });
};

function EventPage() {
  const { id: eventId } = useParams();
  const [event, setEvent] = useState(null);
  const [timeArray, setTimeArray] = useState([]);
  const [actionCounts, setActionCounts] = useState({});
  const [loading, setLoading] = useState(true);
  const [averages, setAverages] = useState({});
  const [sortedKeys, setSortedKeys] = useState([]);

  const [error, setError] = useState(null);
  const [connected, setConnected] = useState(false);

  const fetchEventDetails = useCallback(async () => {
    try {
      const eventDetails = await EventsService.getEvent(eventId);
      setEvent(eventDetails);
    } catch (error) {
      console.error(error.message);
      alert("Failed to fetch event details.");
    }
  }, [eventId]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const newSortedKeys = Array.from(sortedKeys);
    const [removed] = newSortedKeys.splice(result.source.index, 1);
    newSortedKeys.splice(result.destination.index, 0, removed);

    setSortedKeys(newSortedKeys);
  };

  useEffect(() => {
    if (sortedKeys.length === 0) {
      setSortedKeys(Object.keys(averages).sort());
    }
  }, [averages]);

  const handleRefreshAveragesClick = () => {
    calculateAverages(timeArray, event);
  };

  const calculateAverages = (timeArray, event) => {
    const actionGroups = {};
    const averages = {};

    // Group timepoints by actionId
    timeArray.forEach((timepoint) => {
      if (!actionGroups[timepoint.actionId]) {
        actionGroups[timepoint.actionId] = [];
      }
      actionGroups[timepoint.actionId].push(new Date(timepoint.time).getTime());
    });

    const eventActions = event.actions.map((action, index) => {
      return {
        ...action,
        color: actionsColorMap[index],
      };
    })

    // Calculate averages for the same action
    Object.keys(actionGroups).forEach((actionId) => {
      const times = actionGroups[actionId].sort((a, b) => a - b);
      const intervals = times.slice(1).map((t, i) => t - times[i]);

      const averageInterval = intervals.length
        ? intervals.reduce((a, b) => a + b, 0) / intervals.length
        : 0;

      const minInterval = intervals.length ? Math.min(...intervals) : 0;
      const maxInterval = intervals.length ? Math.max(...intervals) : 0;
      const lastInterval =
        times.length > 1
          ? times[times.length - 1] - times[times.length - 2]
          : 0;

      averages[actionId] = {
        label: `"${
          event.actions.find((a) => a.id === actionId)?.name || actionId
        }" <-> "${
          event.actions.find((a) => a.id === actionId)?.name || actionId
        }"`,
        value: averageInterval
          ? `${(averageInterval / 1000).toFixed(2)}s`
          : "N/A",
        minValue: minInterval ? `${(minInterval / 1000).toFixed(2)}s` : "N/A",
        maxValue: maxInterval ? `${(maxInterval / 1000).toFixed(2)}s` : "N/A",
        lastInterval: lastInterval
          ? `${(lastInterval / 1000).toFixed(2)}s`
          : "N/A",
        action1Color: eventActions.find((a) => a.id === actionId)?.color || 'white',
        action2Color: eventActions.find((a) => a.id === actionId)?.color || 'white',
      };
    });

    // Calculate averages, min, max, and last interval between different actions
    for (let i = 0; i < event.actions.length; i++) {
      for (let j = i + 1; j < event.actions.length; j++) {
        const action1 = eventActions[i];
        const action2 = eventActions[j];
        const times1 = actionGroups[action1.id] || [];
        const times2 = actionGroups[action2.id] || [];
        const combined = [
          ...times1.map((t) => ({ time: t, type: 1 })),
          ...times2.map((t) => ({ time: t, type: 2 })),
        ];
        combined.sort((a, b) => a.time - b.time);

        const intervals = [];
        let minInterval = Infinity;
        let maxInterval = -Infinity;
        let lastInterval = 0;

        for (let k = 1; k < combined.length; k++) {
          if (combined[k - 1].type !== combined[k].type) {
            const interval = combined[k].time - combined[k - 1].time;
            intervals.push(interval);
            if (interval < minInterval) minInterval = interval;
            if (interval > maxInterval) maxInterval = interval;
          }
        }

        if (times1.length > 0 && times2.length > 0) {
          const lastTime1 = Math.max(...times1);
          const lastTime2 = Math.max(...times2);
          lastInterval = Math.abs(lastTime1 - lastTime2);
        }

        const averageInterval = intervals.length
          ? intervals.reduce((a, b) => a + b, 0) / intervals.length
          : 0;

        averages[`${action1.id}-${action2.id}`] = {
          label: `"${action1.name}" <-> "${action2.name}"`,
          value: averageInterval
            ? `${(averageInterval / 1000).toFixed(2)}s`
            : "N/A",
          minValue:
            minInterval !== Infinity
              ? `${(minInterval / 1000).toFixed(2)}s`
              : "N/A",
          maxValue:
            maxInterval !== -Infinity
              ? `${(maxInterval / 1000).toFixed(2)}s`
              : "N/A",
          lastInterval: lastInterval
            ? `${(lastInterval / 1000).toFixed(2)}s`
            : "N/A",
            action1Color: action1.color || 'white',
            action2Color: action2.color || 'white',
        };
      }
    }

    setAverages(averages);
  };

  const fetchTimeArray = useCallback(async () => {
    try {
      const timeArrayData = await EventsService.getTimeArray(eventId);
      setTimeArray(timeArrayData);

      // Recalculate action counts
      const counts = timeArrayData.reduce((acc, entry) => {
        acc[entry.actionId] = (acc[entry.actionId] || 0) + 1;
        return acc;
      }, {});
      setActionCounts(counts);
    } catch (error) {
      console.error(error.message);
      alert("Failed to fetch time array.");
    }
  }, [eventId]);

  const handleEndEventClick = async () => {
    try {
      await EventsService.endEvent(eventId);
      setEvent({ ...event, finished: true });
    } catch (err) {
      setError("Failed to end event.");
    }
  };

  const handleUndoClick = async () => {
    try {
      await EventsService.undoTimepoint(eventId);
      const newTimeArray = [...timeArray];
      newTimeArray.pop();
      setTimeArray(newTimeArray);
    } catch (err) {
      setError("Failed to undo timepoint.");
    }
  };

  const handleActionClick = async (typeId, time) => {
    if(event.finished) return;
    try {
      const timePoint = await EventsService.addTimepoint(eventId, typeId, time);
      setTimeArray([...timeArray, timePoint]);

      // Refresh the time array and update the counts
      calculateAverages([...timeArray, timePoint], event);
      const counts = [...timeArray, timePoint].reduce((acc, entry) => {
        acc[entry.actionId] = (acc[entry.actionId] || 0) + 1;
        return acc;
      }, {});
      setActionCounts(counts);
    } catch (err) {
      setError(`Failed to record action: ${time}`);
    }
  };

  useEffect(() => {
    const initializePage = async () => {
      setLoading(true);
      await fetchEventDetails();
      await fetchTimeArray();
      setLoading(false);
    };

    initializePage();
  }, [fetchEventDetails, fetchTimeArray, eventId]);

  const serviceUuid = "4fafc201-1fb5-459e-8fcc-c5c9c331914b";
  const characteristicUuid = "beb5483e-36e1-4688-b7f5-ea07361b26a8";

  const handleConnectClick = async () => {
    try {
      let bluetoothDevice = await navigator.bluetooth.requestDevice({
        filters: [{ name: "FishikaBLE" }],
        optionalServices: [serviceUuid],
      });

      bluetoothDevice.addEventListener("gattserverdisconnected", () => {
        setConnected(false);
        exponentialBackoff(
          3 /* max retries */,
          2 /* seconds delay */,
          function toTry() {
            console.log("Connecting to Bluetooth Device... ");
            return bluetoothDevice.gatt.connect();
          },
          async function success() {
            console.log("> Bluetooth Device connected. Try disconnect it now.");
            const service = await server.getPrimaryService(serviceUuid);

            let characteristic = await service.getCharacteristic(
              characteristicUuid
            );
            //setCharacteristic(characteristic);

            characteristic.addEventListener(
              "characteristicvaluechanged",
              handleNotifications
            );
            await characteristic.startNotifications();
            setConnected(true);
          },
          function fail() {
            console.log("Failed to reconnect.");
          }
        );
      });
      //setBluetoothDevice(bluetoothDevice);

      const server = await bluetoothDevice.gatt.connect();

      const service = await server.getPrimaryService(serviceUuid);

      let characteristic = await service.getCharacteristic(characteristicUuid);
      //setCharacteristic(characteristic);

      characteristic.addEventListener(
        "characteristicvaluechanged",
        handleNotifications
      );
      await characteristic.startNotifications();
      setConnected(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleNotifications = (event) => {
    const value = new TextDecoder().decode(event.target.value);
    const valueObj = JSON.parse(value);
    handleActionClick(valueObj.type, Math.floor(Date.now() / 1000));
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return <p style={{ color: "red" }}>{error}</p>;
  }

  if (!event) {
    return <p>No event found.</p>;
  }

  return (
    <>
      <div className="page-header">
        <nav className="navbar navbar-expand-lg d-flex justify-content-between">
          <div className="header-title flex-fill">
            <h5>
              My Events {">"} Event: {event.name}
            </h5>
          </div>
        </nav>
      </div>
      <div className="main-wrapper">
        <div className="event-settings-actions">
          {connected ? (
            <button
              className="btn btn-success mb-3 btn-lg"
              style={{ marginRight: "16px" }}
            >
              <i className="fab fa-bluetooth-b"></i>
              {/* Connect to KronixBT */}
            </button>
          ) : (
            <button
              className="btn btn-primary mb-3 btn-lg"
              style={{ marginRight: "16px" }}
              onClick={handleConnectClick}
            >
              <i className="fab fa-bluetooth-b"></i> {/* Connect to KronixBT */}
            </button>
          )}

          <button
            className="btn btn-info mb-3 btn-lg"
            onClick={handleRefreshAveragesClick}
            style={{ marginRight: "16px" }}
          >
            <i className="fas fa-sync"></i> {/* Refresh Averages */}
          </button>

          {!event.finished && (
            <button
              className="btn btn-danger mb-3 btn-lg"
              onClick={handleEndEventClick}
              style={{ marginRight: "16px" }}
            >
              <i className="fas fa-stop"></i> {/* End Event */}
            </button>
          )}

          <button
            className="btn btn-warning mb-3 btn-lg"
            onClick={handleUndoClick}
          >
            <i className="fas fa-undo"></i> {/* Undo Action */}
          </button>
        </div>
        <div className="row">
          <div className="card-body r card-bg">
            <div
              style={{ display: "flex", flexWrap: "wrap" }}
              className="actions-button-wrapper"
            >
              {event.actions.map((action, index) => (
                <button
                  key={action.id}
                  onClick={() =>
                    handleActionClick(
                      action.typeId,
                      Math.floor(Date.now() / 1000),
                      timeArray
                    )
                  }
                  style={{
                    backgroundColor: actionsColorMap[index],
                  }}
                >
                  <h1 className="action-count">
                    {actionCounts[action.id] || 0}
                  </h1>
                  <span>{action.name}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable
            droppableId="averages-list"
            direction="horizontal" // Important for wrapping in flexbox
          >
            {(provided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "10px",
                }}
              >
                {sortedKeys.map((key, index) => {
                  const average = averages[key];
                  return (
                    <Draggable key={key} draggableId={key} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            padding: "10px",
                            borderRadius: "5px",
                          }}
                          className="card event-card widget widget-info"
                        >
                          <div
                            className="card-body r card-bg"
                            style={{
                              background: `linear-gradient(to right, ${
                                average.action1Color
                              } 50%, ${
                                average.action2Color
                              } 50%)`,
                            }}
                          >
                            <p
                              style={{
                                margin: 0,
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              {average.label}
                            </p>
                            <p
                              style={{
                                margin: 0,
                                fontWeight: "bold",
                                color: "black",
                              }}
                            >
                              Average: {average.value}
                            </p>
                            {average.minValue !== "N/A" && (
                              <p
                                style={{
                                  margin: 0,
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                Min: {average.minValue}
                              </p>
                            )}
                            {average.maxValue !== "N/A" && (
                              <p
                                style={{
                                  margin: 0,
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                Max: {average.maxValue}
                              </p>
                            )}
                            {average.lastInterval !== "N/A" && (
                              <p
                                style={{
                                  margin: 0,
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                Last: {average.lastInterval}
                              </p>
                            )}
                          </div>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        <TimelineSelector
          timeArray={timeArray}
          actions={event.actions.map((action, index) => {
            return {
              ...action,
              color: actionsColorMap[index],
            };
          })}
        />

        <TimepointCalendar
          timeArray={timeArray}
          actions={event.actions.map((action, index) => {
            return {
              ...action,
              color: actionsColorMap[index],
            };
          })}
        />
      </div>
    </>
  );
}

export default EventPage;
