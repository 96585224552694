import React from "react";
import "./TimepointCalendar.css"; // Create this CSS file for styling

export const TimepointCalendar = ({ timeArray, actions }) => {
  return (
    <div className="timepoint-calendar">
      <h2>Timepoints</h2>
      {timeArray.length > 0 ? (
        <ul>
          {timeArray.sort((a,b) => new Date(b.time) - new Date(a.time)).map((timepoint, index) => {
            // Find the action corresponding to the actionId
            const action = actions.find(
              (action) => action.id === timepoint.actionId
            );

            return (
              <li key={index} style={{backgroundColor:action?.color || ''}}>
                <span style={{width:'100%'}}>{action ? action.name : "Unknown"}{" "}</span>
                <span style={{fontSize:'24px'}}>{new Date(timepoint.time).toLocaleTimeString()}</span>
              </li>
            );
          })}
        </ul>
      ) : (
        <p>No timepoints recorded yet.</p>
      )}
    </div>
  );
};
