import React, {useState, useEffect} from "react";
import {jwtDecode} from "jwt-decode";
import { useNavigate } from "react-router-dom";
import EventsService from "../services/EventsService";

function HomePage() {
  const token = localStorage.getItem("token");
  let username = "";

  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  if (token) {
    try {
      const decoded = jwtDecode(token); // Decode the token
      console.log(decoded, "decoded");
      username = decoded.username; // Extract userId
    } catch (error) {
      console.error("Invalid token:", error.message);
    }
  }

  const fetchEvents = async () => {
    try {
      setLoading(true);
      const data = await EventsService.getAllEvents();
      setEvents(data);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <>
      <div className="page-header">
        <nav className="navbar navbar-expand-lg d-flex justify-content-between">
          <div className="header-title flex-fill">
            <a id="sidebar-toggle" onClick={()=>{
              document.body.classList.toggle("sidebar-hidden");
            }}>
              <i className="fas fa-bars"></i>
            </a>
            <h5>Dashboard</h5>
          </div>
        </nav>
      </div>
      <div className="main-wrapper">
        <p>Device ID: {username || "Unknown"}</p>
      </div>
    </>
  );
}

export default HomePage;
