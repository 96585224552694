import React from "react";
import "./TimelineSelector.css"; // Create this CSS file for styling

export const TimelineSelector = ({ timeArray, actions }) => {
  if (!timeArray || timeArray.length === 0) {
    return <div>No timepoints available</div>;
  }

  // Determine the range of times
  const times = timeArray.map((t) => new Date(t.time).getTime());
  const minTime = Math.min(...times);
  const maxTime = Math.max(...times);

  // Calculate the percentage position for each timepoint
  const getPosition = (time) => {
    const currentTime = new Date(time).getTime();
    return ((currentTime - minTime) / (maxTime - minTime)) * 100;
  };


  return (
    <div className="timeline-selector">
      <div className="timeline-line">
        {timeArray.map(({ time, actionId }, index) => {
          const action = actions.find((action) => action.id === actionId);
          return (
            <div
              key={'timeline-dot-' + index} // Add a unique key'}
              className="timeline-dot"
              style={{ left: `${getPosition(time)}%`, backgroundColor: action?.color }}
            >
              <div className="timeline-popup popover">
                <p className="timeline-time">
                  {new Date(time).toLocaleString()}
                </p>
                <p className="timeline-type">
                  {action?.name || "Unknown"}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
